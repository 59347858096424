function printasciiart() {
    fetch(`/${quart_static_folder}/assets/ascii-art.txt`)
    .then((res) => res.text())
    .then((text) => {
        console.log('%c ' + text, 'color: #ffd700');
    })
    .catch((e) => console.error(e));
}

window.addEventListener("load", (event) => {
    printasciiart('static');
});